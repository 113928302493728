<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%;padding-bottom:0px;">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">
				<el-select size="small" placeholder="请选择年份" v-model="year" clearable
					style="width: 150px;margin-left: 10px;margin-bottom:10px;">
					<el-option :label="y" :value="y" v-for="(y, i) in yearList" :key="i"></el-option>
				</el-select>

				<el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					@change="getSelectType" clearable placeholder="请选择班级" filterable
					style="width: 250px;margin-left: 10px;margin-bottom:10px;" size="small">
				</el-cascader>

				<el-select size="small" v-model="searchForm.xuezhi" placeholder="学制" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option label="中职" value="中职"></el-option>
					<el-option label="高职" value="高职"></el-option>
				</el-select>

				<el-select size="small" v-model="searchForm.jyqx" placeholder="就业去向" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(jyqx, idx) in jyqxArr" :label="jyqx" :value="jyqx" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.chanye" placeholder="所属产业" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(chanye, idx) in chanyeArr" :label="chanye" :value="chanye"
						:key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.dwxz" placeholder="单位性质" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(dwxz, idx) in dwxzArr" :label="dwxz" :value="dwxz" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.didian" placeholder="单位地点类别" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(didian, idx) in ddArr" :label="didian" :value="didian" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.diyu" placeholder="就业地域" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(dy, idx) in dyArr" :label="dy" :value="dy"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.qudao" placeholder="就业渠道" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(qd, idx) in qdArr" :label="qd" :value="qd" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.duikou" placeholder="是否对口就业" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option label="是" value="是"></el-option>
					<el-option label="否" value="否"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.hetong" placeholder="合同签订情况" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(ht, idx) in htArr" :label="ht" :value="ht" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.salary" placeholder="起薪情况" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(xinzi, idx) in xinziArr" :label="xinzi" :value="xinzi" :key="idx"></el-option>
				</el-select>
				<el-select size="small" v-model="searchForm.wuxian" placeholder="社会保险情况" clearable
					style="width:150px;margin-left: 10px;margin-bottom:10px;">
					<el-option v-for="(wx, idx) in wxArr" :label="wx" :value="wx" :key="idx"></el-option>
				</el-select>
				<el-input placeholder="学生姓名或手机号" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:250px;margin-left: 10px;margin-bottom:10px;" clearable></el-input>
				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px;margin-bottom:10px;"
					size="small" type="primary">搜索</el-button>
				<el-button icon="el-icon-download" @click="dialogShow = true"
					style="margin-left:10px;margin-bottom:10px;" size="small" type="primary"
					title="请先选择班级">导出就业名册</el-button>
			</div>
		</el-row>
		<div class="dividerBar"></div>
		<div class="w_100 flex_1 bcontent flexCloumn" id="myFullTable" style="background-color: #fff;">
			<div v-if="tableData.length > 0" class="w_100" style="border: 1px solid #ccc; padding: 10px;">
				<span style="margin-right: 40px;">学校：{{ schoolName || tableData[0].schoolName }}</span>
				<span style="margin-right: 40px;">班级：{{ tableData[0].class_name }}</span>
				<span style="margin-right: 40px;">班级学生数：{{ tableData[0].zs_stu_count }}</span>
				<span style="margin-right: 40px;">毕业学生数：{{ tableData[0].by_stu_count }}</span>
				<span style="margin-right: 40px;">班主任姓名及联系电话：{{ tableData[0].brz_name
				}}&nbsp;&nbsp;{{ tableData[0].bzr_phone
					}}</span>
			</div>
			<el-table :data="tableData" row-key="id" class="flex_1 mytable" header-row-class-name="mytableheader" stripe
				size="mini" height="100%" @selection-change="handleSelectionChange"
				style="width:fit-content;width:100%;">

				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
				<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
				<el-table-column label="姓名" prop="stu_name" width="100" align="center"></el-table-column>
				<el-table-column label="性别" prop="gender" width="80" align="center"></el-table-column>
				<el-table-column label="年龄" prop="age" width="80" align="center"></el-table-column>
				<el-table-column label="学制" prop="xuezhi" width="80" align="center"></el-table-column>
				<el-table-column label="就业去向" prop="jyqx" width="80" align="center"></el-table-column>
				<el-table-column label="专业名称" prop="xibu_name" width="140" align="center"></el-table-column>
				<el-table-column label="专业类别" prop="zhuanye_type" width="140" align="center"></el-table-column>
				<el-table-column label="专业代码" prop="zydm" width="120" align="center"></el-table-column>
				<!--				<el-table-column label="户籍所在地（省、市、区/县）" prop="jtzz" width="150" align="center"></el-table-column>-->
				<el-table-column label="最高职业技能等级/职业资格证" prop="prize_skill" width="140" align="center"></el-table-column>
				<el-table-column label="全日制升学" align="center">

					<el-table-column label="学校名称" prop="enter_name" width="180" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_name : '' }}
						</template>
					</el-table-column>
					<el-table-column label="学历" prop="enter_type" width="100" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_type : '' }}
						</template>
					</el-table-column>

				</el-table-column>
				<!-- <el-table-column label="毕业去向" prop="quxiang" width="180" align="center"></el-table-column> -->
				<el-table-column label="就业状况" align="center">

					<el-table-column label="就业单位名称" prop="enter_name" width="180" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang != '升入高一级学校' ? scope.row.enter_name : '' }}
						</template>
					</el-table-column>
					<el-table-column label="单位地址（省、市）" prop="enter_addr" width="130" align="center"></el-table-column>
					<el-table-column label="单位地点类别" prop="didian" width="120" align="center"></el-table-column>
					<el-table-column label="单位性质" prop="dwxz" min-width="150" align="center"></el-table-column>
					<el-table-column label="所属产业" prop="chanye" width="120" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang != '升入高一级学校' ? scope.row.chanye : '' }}
						</template>
					</el-table-column>
					<el-table-column label="部门" prop="stu_dept" width="120" align="center"></el-table-column>
					<el-table-column label="岗位" prop="stu_job" width="120" align="center"></el-table-column>
					<el-table-column label="是否对口就业" prop="duikou" width="80" align="center"></el-table-column>
					<el-table-column label="是否学校推荐" prop="qudao" width="80" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang != '升入高一级学校' ? (scope.row.qudao == '学校推荐' ? '是' : scope.row.qudao
								==
								'自联' ? '否' : scope.row.qudao) : '' }}
						</template>

					</el-table-column>
				</el-table-column>

				<el-table-column label="劳 动 保 障" align="center">

					<el-table-column label="有否劳动合同" prop="hetong" width="80" align="center">

					</el-table-column>
					<el-table-column label="有否三险五险" prop="enter_type" width="80" align="center">
						<template slot-scope="scope">
							{{ scope.row.wuxian.replace('一金', '') }}
						</template>
					</el-table-column>
					<el-table-column label="有否公积金" prop="gongjijin" width="80" align="center">

					</el-table-column>
					<el-table-column label="平均月工资(元)" prop="salary" width="100" align="center">
						<template slot-scope="scope">
							{{ scope.row.quxiang != '升入高一级学校' ? scope.row.salary : '' }}
						</template>
					</el-table-column>

				</el-table-column>
				<el-table-column label="联 系 电 话" align="center">

					<el-table-column label="本人电话" prop="lxdh" width="130" align="center"></el-table-column>
					<el-table-column label="家长电话" prop="lxdh" width="130" align="center"></el-table-column>
				</el-table-column>

				<el-table-column label="就业是否满意" prop="manyidu" width="80" align="center"></el-table-column>
				<el-table-column label="备注" prop="bz" width="150" align="center"></el-table-column>

				<!-- <el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="viewInfo(scope.row)" type="text" size="small">详情</el-button>
					</template>
				</el-table-column> -->
			</el-table>

			<div style="padding-top: 10px;">
				<el-pagination background @current-change="handlePageChange" @size-change="handleSizeChange"
					:current-page="page.current_page" :page-size="page.per_page" :total="page.count"
					:page-sizes="[20, 50, 100, 500]" layout="total,sizes, prev, pager, next"></el-pagination>
			</div>



		</div>

		<jiuyeinfo v-if="infoShow" :id="sxId"></jiuyeinfo>


		<el-dialog class="cus_dialog " title="请选择导出班级" :visible.sync="dialogShow" :close-on-click-modal="false"
			width="600px" height="30%">

			<el-tree ref="clsTree" :data="TreeData" show-checkbox node-key="name" default-expand-all
				:props="defaultProps">
			</el-tree>


			<div style="text-align: right; padding: 20px;">

				<el-button type="primary" icon="el-icon-download" @click="exportMingceZip">确认导出</el-button>

			</div>

		</el-dialog>

		<el-dialog class="cus_dialog " title="导入记录" :visible.sync="showImportLog" :close-on-click-modal="false"
			custom-class="import-dialog" width="800px">

			<el-table :data="failedImportData" style="width: 100%;padding-top: 20px;">
				<el-table-column label="查看" width="80" type="expand">
					<template slot-scope="props">
						<div v-for="(value, index) in props.row.errors" :key="index"
							style="color:#99a9bf;line-height: 26px;">{{ value.msg }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="filename" label="导入文件">
					<template v-slot="scope">
						<el-link :href="scope.row.file_url" type="primary">{{ scope.row.filename }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="cdate" label="导入时间"></el-table-column>
				<el-table-column prop="username" label="导入人"></el-table-column>
			</el-table>

		</el-dialog>

	</div>
</template>

<script>
import jiuyeinfo from './jiuyeinfo.vue'
export default {
	components: { jiuyeinfo },
	name: "jymingce",
	props: ['tpmid'],
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			infoShow: false,
			dialogshowPhone: false,
			sxId: 0,
			searchForm: {
				class_name: "",
				keyword: "",
				status: "",
				school_id: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			SchoolList: [],
			checkedRows: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'value',
				children: 'children',
				emitPath: true,
				checkStrictly: true,

			},
			shixi: null,
			schoolName: "",
			is_admin: false,
			sysAdmin: false,
			sysOpened: false,
			showImportLog: false,
			failedImportData: [],
			jyqxArr: ['就业', '升学', '参军', '出国', '创业', '待业', '其他'],
			dwxzArr: ['国有企业', '外资企业', '合资企业', '私营企业', '事业单位', '国家机关'],
			htArr: ['未签合同', '1年及以内', '1～2(含)年', '2～3(含)年', '3年以上'],
			xinziArr: ['2490及以下', '2490～4000', '4000～5000', '5000～6000', '6000以上'],
			wxArr: ['没有社保', '三险', '五险', '三险一金', '五险一金'],
			chanyeArr: ['第一产业', '第二产业', '第三产业'],
			ddArr: ['城区', '镇区', '乡村'],
			qdArr: ['学校推荐', '自联', '其他渠道'],
			dyArr: ['本地', '异地', '境外'],
			uid: 130,
			year: new Date().getFullYear(),
			yearList: [],
		}
	},
	mounted() {
		let yearList = [];
		for (let i = new Date().getFullYear(); i >= 2021; i--) {
			yearList.push(i)
		}
		this.yearList = yearList;
		if (sessionStorage.getItem("year")) {
			this.year = sessionStorage.getItem("year")
		}

		this.getDataList()
		this.getTree()
		this.getSchoolName()
		setTimeout(() => {
			let roles = this.$store.state.teacher.sys_roles
			this.is_admin = roles.filter(r => r.role_code == 'xxgly').length > 0
			this.sysAdmin = roles.filter(r => r.role_code == 'sysadmin').length > 0
		}, 500)
	},
	methods: {

		getSchoolName() {
			this.$http.post("http://api-qsx-jyj.qushixi.org.cn/api/jydc_config", { pmid: 211 }).then(res => {
				for (let item of res.data) {
					if (item.ktype == "schoolname") {
						this.schoolName = item.kvalue
					}
				}
			})
		},
		// getSchoolList() {
		// 	this.$http.post("http://api-qsx-jyj.qushixi.org.cn/api/school_list").then(res => {
		// 		this.SchoolList = res.data
		// 	})
		// },

		getTree() {
			this.$http.post("http://api-qsx-jyj.qushixi.org.cn/api/jydc_jymc_grade_tree", { school_id: this.tpmid, pmid: 211, year: 2024, uid: this.uid }).then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			this.$nextTick(() => {
				let node = this.$refs['firstCascader'].getCheckedNodes()
				if (node[0]) {
					let path = node[0].pathNodes[0].label
					if (node[0].pathNodes.length >= 2) {
						this.searchForm.xibu_name = node[0].pathNodes[0].label
						this.searchForm.class_name = node[0].pathNodes[1].label
					} else if (node[0].pathNodes.length >= 1) {
						this.searchForm.xibu_name = node[0].pathNodes[0].label
						this.searchForm.class_name = ""
					} else {
						this.searchForm.xibu_name = ""
						this.searchForm.class_name = ""
					}
					console.log(348, this.searchForm)
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""

				}
			})
		},

		getDataList() {
			let data = {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				school_id: this.tpmid,
				showloading: true,
				all: false,
				...this.searchForm,
				pmid: 211,
				year: this.year,
				uid: this.uid,
			}

			this.$http.post("http://api-qsx-jyj.qushixi.org.cn/api/jydc_jymc_list", data).then(async res => {
				// this.$http.post("http://192.168.0.110:11050/api/jydc_jymc_list", data).then(async res => {
				for (let item of res.data.data) {

					// 			item.bzr_phone = await this.decryptWord(item.bzr_phone)
					//   item.lxdh = await this.decryptWord(item.lxdh)
					//   item.jz_phone = await this.decryptWord(item.jz_phone)
					item.bzr_phone = '***'
					item.lxdh = '***'
					item.jz_phone = '***'
				}

				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.sxId = e.id

			this.infoShow = true
		},
		handleSelectionChange(val) {
			this.checkedRows = val
		},

		exportMingceZip() {
			let clsNames = this.$refs['clsTree'].getCheckedKeys(true)
			this.$http.post("http://api-qsx-jyj.qushixi.org.cn/api/jydc_export_jymc", { clsNames, pmid: 211, year: this.year, uid: this.uid }).then(res => {
				// this.$http.post("http://192.168.0.110:11050/api/jydc_export_jymc", { clsNames, pmid: 211, year: this.year,uid:this.uid }).then(async res => {
				this.dialogShow = false
				window.open(res.data.url, "_blank")
			})
		},

		getFailedImportData() {
			this.$http.post('http://api-qsx-jyj.qushixi.org.cn/api/get_failed_import_data').then(res => {
				res.data.map(item => {
					item.errors = JSON.parse(item.errors)
				})
				this.failedImportData = res.data
			})
		},
		getImportLog() {
			this.showImportLog = true
			this.getFailedImportData()
		},
		handleSizeChange(val) {
			this.page.per_page = val
			this.page.current_page = 1
			this.getDataList()
		},
	}
}
</script>
<style>
.custom-msg .el-message-box__title {
	color: orangered;
	font-size: 18px;
	font-weight: bold;
	line-height: 26px;
	padding-right: 11px;
}

.custom-msg .el-message-box__message {
	font-size: 18px;
	font-weight: bold;
}

.custom-tip {
	font-size: 18px;
}

.import-dialog .el-dialog__body {
	max-height: 60vh !important;
}
</style>
</style>
